import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Styles/order.css";
import { AuthLoginInfo } from "./../AuthComponents/AuthLogin";
import Popup from "../Components/Popup";
import SearchBar from "../Components/SearchBar";
import Pagination from "../Components/Pagination";
import ReadMoreRoundedIcon from "@mui/icons-material/ReadMoreRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";
import { API_URL } from "../helpers/helper";
function Orders() {
  const [newOrderSubmitted, setNewOrderSubmitted] = useState(false);
  const [errors,setErrors]=useState([]);
  const [ordersData, setOrdersData] = useState([]);
   const [filteredData, setFilteredData] = useState([]);

  const [buttonPopup, setButtonPopup] = useState(false);

  const [filterOrders, setFilterOrders] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterActive, setFilterActive] = useState(1);

  const [isNewClient, setIsNewClient] = useState(true);
  const [displaySearch, setDisplaySearch] = useState(false);
  const [oldClientId, setOldClientId] = useState(null);
  const [stringSearch, setStringSearch] = useState("");
  const [allClientsData, setAllClientsData] = useState([]);

  const ctx = useContext(AuthLoginInfo);

  console.log(ordersData)

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    clientDetails: "",
    phone: "",
    country: "Polska",
    street: "",
    city: "",
    postalCode: "",
    status: "Open",
    products: [],
    workerName: ctx.username,
  });
  const [productDetails, setProductDetails] = useState({
    productName: "",
    amount: 1,
    itemPrice: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    setNewOrderSubmitted(false);
    axios
      .get(`${API_URL}/orders`, { withCredentials: true })
      .then((res) => {
        if (res.data != null) {
          setOrdersData(res.data);
          setFilteredData(res.data);
        }
      });
  }, [newOrderSubmitted]);

  const handleSearchChange = (newFilteredData) => {
    setFilteredData(newFilteredData);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const totalOrders = filteredData.length;
  const computedOrders = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    (currentPage - 1) * itemsPerPage + itemsPerPage
  );

  const removeProduct = (e) => {
    let array = clientDetails.products;
    console.log(array);
    let index = clientDetails.products.e;
    if (index !== -1) {
      toast.success("Product removed");
      array.splice(index, 1);
      setClientDetails({
        ...clientDetails,
        products: array,
      });
    }
  };

  const addNewOrder = () => {
    let tempErrors=[];
    if(!clientDetails.clientName){
      tempErrors.push({field:"clientName",ValidationMessage:"Client Name required"});
    }
    if(!clientDetails.clientDetails){
      tempErrors.push({field:"clientDetails",ValidationMessage:"Details required"});
    }
    if(!clientDetails.phone){
      tempErrors.push({field:"phone",ValidationMessage:"Phone required"});
    }
    if(!clientDetails.country){
      tempErrors.push({field:"country",ValidationMessage:"Country required"});
    }
    if(!clientDetails.street){
      tempErrors.push({field:"street",ValidationMessage:"Street required"});
    }
    if(!clientDetails.city){
      tempErrors.push({field:"city",ValidationMessage:"City required"});
    }
    if(!clientDetails.postalCode){
      tempErrors.push({field:"postalCode",ValidationMessage:"Postal code required"});
    }
    setErrors(tempErrors);
    if(tempErrors.length<=0){
    axios
      .post(
        `${API_URL}/neworder`,
        {
          clientDetails,
          isNewClient,
          oldClientId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data === "success") {
          toast.success("Order created successfully");
          setClientDetails({
            clientName: "",
            clientDetails: "",
            phone: "",
            country: "Polska",
            street: "",
            city: "",
            postalCode: "",
            status: "",
            products: [],
            workerName: ctx.username,
          });
          setProductDetails({
            productName: "",
            amount: 1,
            itemPrice: 0,
            totalPrice: 0,
          });
          setNewOrderSubmitted(true);
        }else{
          toast.error("error creating order");
        }
      });
    }
};
useEffect(()=>{
  setErrors([]);
},[clientDetails.clientName,clientDetails.city,clientDetails.clientDetails,clientDetails.country,clientDetails.phone,
clientDetails.postalCode,clientDetails.status,clientDetails.street]);

  const getAllClientsFromDatabase = () => {
    axios
      .get(`${API_URL}/clients`, { withCredentials: true })
      .then((res) => {
        setAllClientsData(res.data[0]);
      });
  };

  const setSearchingInput = (
    id,
    client,
    details,
    phone,
    country,
    street,
    city,
    postalCode
  ) => {
    setOldClientId(id);
    setClientDetails({
      ...clientDetails,
      clientName: client,
      clientDetails: details,
      phone: phone,
      country: country,
      street: street,
      city: city,
      postalCode: postalCode,
    });
    setDisplaySearch(false);
    setStringSearch("");
  };

  return (
    <div className="bodyWrap">
      <div className="contentOrderWrap">
        <div className="leftSide">
          <h1>Orders</h1>
          <Pagination
            total={totalOrders}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
          <div className="orderNavWrap">
            <div className="orderNav">
              <ul>
                <li
                  className={`${filterActive === 1 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("");
                    setFilterActive(1);
                  }}
                >
                  All orders
                </li>
                <li
                  className={`${filterActive === 2 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("Open");
                    setFilterActive(2);
                  }}
                >
                  Open
                </li>
                <li
                  className={`${filterActive === 3 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("Closed");
                    setFilterActive(3);
                  }}
                >
                  Closed
                </li>
                <li
                  className={`${filterActive === 4 ? "active" : ""}`}
                  onClick={() => {
                    setFilterOrders("Shipped");
                    setFilterActive(4);
                  }}
                >
                  Shipped
                </li>
              </ul>
            </div>
            <div className="addOrderWrap">
              <SearchBar
                data={ordersData}
                handleSearchChange={handleSearchChange}
                dataType="orders"
                status={filterOrders}
              />
              <button
                className="addOrder"
                onClick={() => {
                  setButtonPopup(true);
                  setIsNewClient(true);
                  getAllClientsFromDatabase();
                }}
              >
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Add</span>
              </button>
            </div>
          </div>
          <div className="orderWrap">
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Client name</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {computedOrders?.map((order) => {
                  return (
                    <tr key={order.id}>
                      <td>
                        <font className="maincolor">#</font>
                        {order.id}
                      </td>
                      <td>{order.client}</td>
                      <td>{order.date.split("T")[0]}</td>
                      <td className={order.status}>{order.status}</td>
                      <td>
                        {order.price}
                        C$
                      </td>
                      <td className="maincolor">
                        <Link to={`/orders/${order.id}`}>
                          <ReadMoreRoundedIcon />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <div className="popupWrap">
          <div className="productSummary">
            <h3 className="productSummaryLeft">Add new order</h3>
            <div className="productSummaryRight newUserSwitch">
              <h3>New client?</h3>
              <input
                type="radio"
                name="rdo"
                id="yes"
                onChange={() => setIsNewClient(true)}
                defaultChecked="defaultChecked"
              />
             
              <input
                type="radio"
                name="rdo"
                id="no"
                onChange={() => setIsNewClient(false)}
              />
              <div className="switch">
                <label className="switchLabel" htmlFor="yes">
                  Yes
                </label>
                <label className="switchLabel" htmlFor="no">
                  No
                </label>
                <span></span>
              </div>
            </div>
          </div>
          <div className="addNewOrderWrap">
            {!isNewClient && (
              <div className="autoCompleteWrap">
                <input
                  id="autoCompleteInput"
                  placeholder="Search client..."
                  type="text"
                  autoComplete="off"
                  value={stringSearch}
                  onChange={(e) => {
                    setStringSearch(e.target.value);
                    if (e.target.value.length > 0) {
                      setDisplaySearch(true);
                    } else {
                      setDisplaySearch(false);
                    }
                  }}
                />{" "}
                {displaySearch && (
                  <div className="autoCompleteContainer">
                    {allClientsData
                      ?.filter((v) => {
                        if (
                          [v.client.toLowerCase()].some((r) =>
                            r.includes(stringSearch)
                          )
                        ) {
                          return v;
                        }
                      })
                      .map((val, i) => {
                        return (
                          <div
                            onClick={() =>
                              setSearchingInput(
                                val.client_id,
                                val.client,
                                val.clientDetails,
                                val.phone,
                                val.country,
                                val.street,
                                val.city,
                                val.postalCode
                              )
                            }
                            className="autoCompleteOption"
                            key={i}
                          >
                            {/* <span>{val.client_id}</span> */}
                            <span>{val.client}</span>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            )}

            <div className="addNewOrderForm">
              <div className="orderDetails">
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Client name"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.clientName}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        clientName: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />   {errors.filter(f=>f.field==='clientName').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='clientName')[0].ValidationMessage}</Alert>:""}
               
               
                  <input
                    type="text"
                    placeholder="Phone number"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.phone}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        phone: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                  {errors.filter(f=>f.field==='phone').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='phone')[0].ValidationMessage}</Alert>:""}
                  
                </div>
                <div className="input-group">
                  <input
                    type="textarea"
                    placeholder="Order details"
                    className="orderDetailsInput"
                    value={clientDetails.clientDetails}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        clientDetails: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                       {errors.filter(f=>f.field==='clientDetails').length > 0 ? <Alert severity="error">{errors.filter(f=>f.field==='clientDetails')[0].ValidationMessage}</Alert>:""}
   
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Country"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.country}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        country: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                           {errors.filter(f=>f.field==='country').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='country')[0].ValidationMessage}</Alert>:""}
   
                  <input
                    type="text"
                    placeholder="Street, house number"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.street}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        street: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                     {errors.filter(f=>f.field==='street').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='street')[0].ValidationMessage}</Alert>:""}
   
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="City"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.city}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        city: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                      {errors.filter(f=>f.field==='city').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='city')[0].ValidationMessage}</Alert>:""}
   
                  <input
                    type="text"
                    placeholder="Postal code"
                    className="orderDetailsInput orderDetailsInputHalf"
                    value={clientDetails.postalCode}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        postalCode: e.target.value,
                      })
                    }
                    disabled={!isNewClient}
                    required="required"
                  />
                       {errors.filter(f=>f.field==='postalCode').length > 0 ? <Alert  severity="error">{errors.filter(f=>f.field==='postalCode')[0].ValidationMessage}</Alert>:""}
   
                </div>
                <div className="input-group">
                  <select
                    className="orderDetailsSelect"
                    placeholder="Status"
                    value={clientDetails.status}
                    onChange={(e) =>
                      setClientDetails({
                        ...clientDetails,
                        status: e.target.value,
                      })
                    }
                    required="required"
                  >
                    <option>Open</option>
                    <option>Closed</option>
                    <option>Shipped</option>
                  </select>
                </div>
              </div>

              <div className="productDetails">
                <div className="newOrderTable">
                  <table>
                    <thead>
                      <tr>
                        <th style={{width:'60%'}}>Product</th>
                        <th style={{width:'15%'}}>Quantity</th>
                        <th style={{width:'15%'}}>Price</th>
                        <th style={{width:'10%'}}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{width:'60%'}}>
                          <input
                            type="text"
                            placeholder="Product name"
                            className="productDetailsInput"
                            value={productDetails.productName}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                productName: e.target.value,
                              })
                            }
                            required="required"
                          />
                        </td>
                        <td style={{width:'15%'}}>
                          <input
                            type="number"
                            placeholder="1"
                            className="productDetailsInput"
                            value={productDetails.amount}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                amount: Number(e.target.value),
                              })
                            }
                            required="required"
                          />
                        </td>
                        <td style={{width:'15%'}}>
                          <input
                            type="number"
                            placeholder="10.00"
                            className="productDetailsInput"
                            value={productDetails.itemPrice}
                            onChange={(e) =>
                              setProductDetails({
                                ...productDetails,
                                itemPrice: Number(e.target.value),
                              })
                            }
                            required="required"
                          />
                        </td>
                        <td style={{width:'10%'}}>
                          {productDetails.itemPrice * productDetails.amount}
                        </td>
                      </tr>
                      {clientDetails.products.map((product, key) => {
                        return (
                          <tr key={key}>
                            <td>{product.productName}</td>
                            <td>{product.amount}</td>
                            <td>{product.itemPrice}</td>
                            <td>{product.amount * product.itemPrice}</td>
                            <td
                              className="removeProduct"
                              onClick={() => removeProduct(key)}
                            >
                              <RemoveRoundedIcon />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="submitWrap">
            <div className="productSummary">
              <div className="productSummaryLeft">
                <span
                  className="addNewLine"
                  onClick={() => {
                    if(productDetails.productName){
                    setClientDetails({
                      ...clientDetails,
                      products: [...clientDetails.products, productDetails],
                    });
                    setProductDetails({
                      productName: "",
                      amount: 1,
                      itemPrice: 0,
                      totalPrice: 0,
                    });
                   }else{
                    toast.warning("Empty product name")
                   }
                  }}
                >
                  + Add product
                </span>
              </div>
              <div className="productSummaryRight">
                <span className="totalCost">
                  Total price of products -{" "}
                  {clientDetails.products.reduce(
                    (a, b) => a + (b.itemPrice * b.amount || 0),
                    0
                  )}
                  C$
                </span>
              </div>
            </div>
            <div className="submitNewOrder">
              <button
                className="submitNewOrderBtn"
                onClick={() => addNewOrder()}
              >
                <AddCircleOutlineRoundedIcon />
                <span className="addOrderText">Add</span>
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default Orders;
