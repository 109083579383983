import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './Styles/login.css';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import { API_URL } from '../helpers/helper';

export default function Login() {
  const [errors,setErrors]=useState([]);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    login();
  }
};
  const login = () => {
    if(!username){
      errors.push({field:"Username",ValidationMessage:"Username required"});
      setErrors(errors);
    }
    if(!password){
      errors.push({field:"Password",ValidationMessage:"Password required"});
      setErrors(errors);
    }
    if(errors.length<=0){
    axios.post(`${API_URL}/login`, {
      username,
      password
    }, {withCredentials: true}).then(res => {
      if (res.data === 'success') {
        window.location.href = '/';
      }else{
        toast.error("Incorrect username or password!");
      }
    }).catch((reason)=>{
      toast.error("Incorrect username or password");
    }); 
  }
  };
  useEffect(()=>{
      setErrors([]);
  },[username,password]);

  return (<div className="bodyWrap">
    <div className="contentLoginWrap">
      <div className="loginSide">
        <div className="loginWrap">
          <h1>Log in</h1>
          <div className="input-group">
            <input type="text" className="input" onKeyDown={handleKeypress} onChange={e => setUsername(e.target.value)} required="required"/>
            <label className={`${username.length > 0 ? "focusLabel" : ""}`}>Login</label>
            {errors.filter(f=>f.field==='Username').length > 0 ? <Alert severity="error">{errors.filter(f=>f.field==='Username')[0].ValidationMessage}</Alert>:""}
          </div>
          
          <div className="input-group">
            <input type="text" className="input password"  onKeyDown={handleKeypress} onChange={e => setPassword(e.target.value)} required="required"/>
            <label className={`${password.length > 0 ? "focusLabel" : ""}`}>Password</label>
            {errors.filter(f=>f.field==='Password').length > 0 ? <Alert severity="error">{errors.filter(f=>f.field==='Password')[0].ValidationMessage}</Alert>:""}
          </div>
          <button onClick={login}>Login</button>
        </div>
      </div>
      <div className="infoSide">
        <div className="loginWrap">
          <h2>Hello again!</h2>
          <p>Log in to your account to get access to app.</p>
        </div>
      </div>
    </div>
  </div>)
}
