import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './Assets/Styles/index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { AuthLogin } from './AuthComponents/AuthLogin';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthLogin>
    <App />
  </AuthLogin>);
registerServiceWorker();
